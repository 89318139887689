import { useEffect, useState } from 'react';
// import https from 'https';
import './dub.css';

import classes from './TextToSpeechSection.module.css';
import Waves from './Waves';
import play from './play.svg';
import pause from './pause.svg';
import star from '../../image/VoiceConversion/star.svg';
import spinner from './spinner.svg';
import dropdown from './dropdown.svg';
import biden1x from './personas/biden/person-biden.png';
import biden2x from './personas/biden/person-biden@2x.png';
import biden3x from './personas/biden/person-biden@3x.png';

import eilish1x from './personas/eilish/person-eilish.png';
import eilish2x from './personas/eilish/person-eilish@2x.png';
import eilish3x from './personas/eilish/person-eilish@3x.png';

import grande1x from './personas/grande/person-grande.png';
import grande2x from './personas/grande/person-grande@2x.png';
import grande3x from './personas/grande/person-grande@3x.png';

import kim1x from './personas/kim/person-kim.png';
import kim2x from './personas/kim/person-kim@2x.png';
import kim3x from './personas/kim/person-kim@3x.png';

import musk1x from './personas/musk/person-musk.png';
import musk2x from './personas/musk/person-musk@2x.png';
import musk3x from './personas/musk/person-musk@3x.png';

import obama1x from './personas/obama/person-obama.png';
import obama2x from './personas/obama/person-obama@2x.png';
import obama3x from './personas/obama/person-obama@3x.png';

import rogan1x from './personas/rogan/person-rogan.png';
import rogan2x from './personas/rogan/person-rogan@2x.png';
import rogan3x from './personas/rogan/person-rogan@3x.png';

import downloadBtn from './download.svg';

export default function InteractiveDub() {
  const [isOverlay, setOverlay] = useState(false);

  const personas = [
    {
      name: 'Elon',
      images: [musk1x, musk2x, musk3x],
      id: 'musk',
    },
    {
      name: 'Kim',
      images: [kim1x, kim2x, kim3x],
      id: 'kim',
    },
    {
      name: 'Joe',
      images: [biden1x, biden2x, biden3x],
      id: 'biden',
    },
    {
      name: 'Billie',
      images: [eilish1x, eilish2x, eilish3x],
      id: 'eilish',
    },
    {
      name: 'Barack',
      images: [obama1x, obama2x, obama3x],
      id: 'obama',
    },
    {
      name: 'Ariana',
      images: [grande1x, grande2x, grande3x],
      id: 'grande',
    },
    {
      name: 'Joe',
      images: [rogan1x, rogan2x, rogan3x],
      id: 'rogan',
    },
  ];

  const handleSpeakerSelect = (idx) => {
    setSelectedSpeaker(idx);
  };

  const openOverlay = (event) => {
    event.preventDefault();
  };

  const sendRequest = async (event) => {
    event.preventDefault();
    setProcessing(true);
    setGotResult(false);
    const d = { voice: personas[selectedSpeaker].id, text: String(input) };
    try {
      // const httpsAgent = new https.Agent({
      //   rejectUnauthorized: false,
      // });
      fetch('https://green.iq.mynalabs.ai:5000/tts_front', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          processData: false,
        },
        body: JSON.stringify(d),
        // agent: httpsAgent,
      })
        .then((response) => response.blob())
        .then((m_blob) => {
          console.log(m_blob);
          const URI = URL.createObjectURL(m_blob);
          console.log(URI);
          let node = document.getElementById('a_audio');
          node.src = URI;
          setGotResult(true);

          setProcessing(false);
          try {
            setPlaying(true);
            node.play();
          } catch (e) {
            console.log(e);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const [input, setInput] = useState('The birch canoe slid on the smooth planks.');
  const [selectedSpeaker, setSelectedSpeaker] = useState(0);
  const [isProcessing, setProcessing] = useState(false);
  const [gotResult, setGotResult] = useState(false);
  const [currentTime, setCurrentTime] = useState('');
  const [currentStage, setCurrentStage] = useState(0);
  const [isPlaying, setPlaying] = useState(false);

  const handleChange = (event) => {
    let string = event.target.value;
    if (string.length > 600) {
      return;
    }
    setInput(string);
  };

  useEffect(() => {
    const player = document.getElementById('a_audio');

    player.addEventListener('loadedmetadata', () => {
      let seconds = player.duration;
      seconds = parseInt(seconds);
      if (seconds < 60) {
        if (seconds < 9) {
          setCurrentTime('00:0' + String(seconds));
        } else {
          setCurrentTime('00:' + String(seconds));
        }
      }
    });
    player.addEventListener('ended', () => {
      setPlaying(false);
      setCurrentTime('00:00');
      setCurrentStage(0);
    });
    player.addEventListener('timeupdate', () => {
      let current = parseInt(player.currentTime);
      if (current < 60) {
        if (current < 9) {
          setCurrentTime('00:0' + String(current));
        } else {
          setCurrentTime('00:' + String(current));
        }
      }
      let stage = parseInt((player.currentTime / player.duration) * 72);
      setCurrentStage(stage);
    });

    window.document.addEventListener('click', (event) => {
      let { target } = event;
      if (target.id === 'select') {
        return;
      }
      if (target.parentNode.id === 'select') {
        return;
      }
      if (target.parentNode.parentNode.id === 'select') {
        return;
      }

      setOverlay(false);
    });
  });

  const toggleAudio = () => {
    const player = document.getElementById('a_audio');
    if (isPlaying) {
      player.pause();
    } else {
      player.play();
    }
    setPlaying(!isPlaying);
  };

  return (
    <div className={classes.container}>
      <h2 className="header">Text-to-Speech</h2>
      <p>
        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of
        using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
      </p>
      <span className={classes.useCasesTitle}>
        <img src={star} alt="star" width={19} />
        USE CASES
      </span>
      <div className={classes.useCases}>
        <span>— Dubbing</span>
        <span>— Localization</span>
        <span>— Replication</span>
        <span>— Accent Removal</span>
        <span>— Anonymization</span>
      </div>

      <audio id="a_audio" controls={true} />
      <div className="interactive-input-container">
        <div className="interactive-input-label">Input text</div>
        <input onChange={handleChange} value={input} type="text" className="interactive-input"></input>
      </div>
      <div className="dub-picker-submit-container">
        <div className="dub-picker relative" id="select" onClick={() => setOverlay(true)}>
          <img
            className="dub-picker-image"
            alt="person"
            src={personas[selectedSpeaker].images[0]}
            srcSet={personas[selectedSpeaker].images[1] + ' 2x,' + personas[selectedSpeaker].images[2] + ' 3x'}
          ></img>
          <div className="dub-picker-content">
            <div className="dub-picker-speaker">Speaker</div>
            <div className="dub-picker-name">{personas[selectedSpeaker].name}</div>
          </div>
          <div className="center-center">
            <img alt="dropdown" src={dropdown} />
          </div>
          <div className="dub-picker-dropdown"></div>
          {isOverlay ? (
            <div className="dub-picker-options">
              {personas.map((person, idx) => {
                return (
                  <div className="dub-picker no-border" onClick={() => handleSpeakerSelect(idx)}>
                    <img
                      className="dub-picker-image"
                      alt="person"
                      src={person.images[0]}
                      srcSet={person.images[1] + ' 2x,' + person.images[2] + ' 3x'}
                    ></img>
                    <div className="dub-picker-content">
                      <div className="dub-picker-speaker">Speaker</div>
                      <div className="dub-picker-name">{person.name}</div>
                    </div>
                    <div className="dub-picker-dropdown"></div>
                  </div>
                );
              })}
            </div>
          ) : (
            <></>
          )}
        </div>
        <button className="dub-generate" disabled={isProcessing} onClick={sendRequest}>
          Generate Audio
        </button>
      </div>
      {isProcessing ? (
        <>
          <div className="dub-result-container" style={{ opacity: '1' }}>
            <div className="dub-result-left">
              <div className="dub-spinner">
                <img src={spinner} alt="spinner" />
              </div>
              <div className="dub-al">
                <div className="dub-audio-label">Generating Audio</div>
                <div className="dub-audio-grey">for your input text</div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {gotResult ? (
            <>
              <div className="dub-result-container" style={{ opacity: '1' }}>
                <div className="dub-result-left">
                  <button className="play-btn" onClick={toggleAudio}>
                    {isPlaying ? <img className="toggle-btn" src={pause} alt="play" /> : <img className="toggle-btn" src={play} alt="play" />}
                  </button>
                  <div className="dub-al">
                    <div className="dub-audio-label">Result Audio</div>
                    <div className="dub-audio-length">{currentTime}</div>
                  </div>
                </div>
                <div className="dub-waves">
                  <Waves waves="85" currentStage={currentStage} />
                </div>
                <button className="download-audio-dub" onClick={openOverlay}>
                  <img src={downloadBtn} alt="download" />
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="dub-result-container" style={{ opacity: '0', height: '0' }}>
                <div className="dub-result-left">
                  <button className="play-btn" onClick={toggleAudio}>
                    {isPlaying ? <img className="toggle-btn" src={pause} alt="play" /> : <img className="toggle-btn" src={play} alt="play" />}
                  </button>
                  <div className="dub-al" style={{ opacity: '0', height: '0' }}>
                    <div className="dub-audio-label">Result Audio</div>
                    <div className="dub-audio-length">{currentTime}</div>
                  </div>
                </div>
                <div className="dub-waves" style={{ opacity: '0', height: '0' }}>
                  <Waves waves="72" currentStage={currentStage} />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
