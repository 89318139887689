import { useState, useEffect } from 'react';
import './interactiveVideoDub.css';
import axios from 'axios';

import star from '../../image/VoiceConversion/star.svg';
import asian from './media/asian.jpeg';
import beauty from './media/beauty.jpeg';
import original from './media/original.jpeg';

export default function InteractiveVideoDub() {
  const maxString = 45;
  const defaultVideo = 'https://yourbuddy-1.s3.eu-central-1.amazonaws.com/lipsync_result/765d929bf46a41c88a2adf08966b80ee.mp4';
  // const defaultVideo = 'https://yourbuddy-1.s3.eu-central-1.amazonaws.com/buddy_lipsync/0001.mp4';
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [text, setText] = useState('Type any text to generate a video with me!');

  const [isPlaying, setPlaying] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [timer, setTimer] = useState(0);

  useEffect(() => {
    if (timer < 0) {
      return;
    } else {
      setTimeout(() => {
        setTimer(timer + 1);
      }, 1000);
    }
  }, [timer]);

  const dubOptions = [
    {
      id: 'Original720',
      image: original,
      name: 'Basic',
    },
    {
      id: 'Beauty720',
      image: beauty,
      name: 'Beauty',
    },
    {
      id: 'Asian720',
      image: asian,
      name: 'Asian',
    },
  ];

  const [charsLeft, setCharsLeft] = useState(maxString);

  useEffect(() => {
    setCharsLeft(parseInt(maxString - text.length));
  }, [text]);

  const playVideo = () => {
    const videoPlayer = document.getElementById('main-video');
    if (isPlaying) {
      videoPlayer.pause();
      setPlaying(false);
      return;
    }

    videoPlayer.play();
    setPlaying(true);

    videoPlayer.addEventListener('ended', () => {
      setPlaying(false);
      videoPlayer.currentTime = 0;
    });
  };

  const sendRequest = async (event) => {
    event.preventDefault();
    setLoading(true);
    setTimer(1);
    const d = { text: text, template_name: dubOptions[selectedIdx].id };
    const player = document.getElementById('main-video');
    const a_req = await axios.post('https://green.iq.mynalabs.ai:5000/lipsync_front', d);
    setTimer(-100);
    console.log('Finished');
    const { s3_link } = a_req.data;
    player.src = s3_link;
    setLoading(false);
  };

  return (
    <>
      <h2 className="header">Video generation & personalisation</h2>
      <p className="text">
        Create as many videos as you need in seconds instead of hours or days just typing the text. You could also customize the look of the
        character.
      </p>
      <span className="useCasesTitle">
        <img src={star} alt="star" width={19} />
        USE CASES
      </span>
      <div className="use">
        <span>— CRM communications</span>
        <span>— Targeted advertising</span>
        <span>— Onboarding videos</span>
      </div>
      <div className="video-dub-container">
        <div className="video-dub-horizontal" id="select">
          <div className="video-dub-player" id="select" onClick={playVideo}>
            <video controls={false} playsInline={true} type="video/mp4" id="main-video" src={defaultVideo} alt="main"></video>
            {isPlaying ? (
              <></>
            ) : (
              <div className="video-overlay" id="select">
                <div className="video-play" id="select">
                  <svg width="35" height="39" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M2.10234 29.2386C2.44325 29.2386 2.76711 29.1761 3.07393 29.0511C3.38075 28.9375 3.70461 28.7784 4.04552 28.5739L23.9205 17.0852C24.6364 16.6648 25.1307 16.2954 25.4035 15.9773C25.6876 15.6477 25.8296 15.2557 25.8296 14.8011C25.8296 14.3466 25.6876 13.9602 25.4035 13.642C25.1307 13.3125 24.6364 12.9432 23.9205 12.5341L4.04552 1.0284C3.70461 0.835222 3.38075 0.681813 3.07393 0.568176C2.76711 0.443176 2.44325 0.380676 2.10234 0.380676C1.47734 0.380676 0.977339 0.602267 0.602339 1.04545C0.238703 1.48863 0.0568848 2.07954 0.0568848 2.81818L0.0568848 26.7841C0.0568848 27.5227 0.238703 28.1136 0.602339 28.5568C0.977339 29.0114 1.47734 29.2386 2.10234 29.2386Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            )}
          </div>
          <div className="video-dub-column">
            <div className="appearance">Appearance</div>
            <div className="video-dub-select">
              {dubOptions.map((object, idx) => {
                return (
                  <div className="dub-option" onClick={() => setSelectedIdx(idx)}>
                    <div className={selectedIdx === idx ? 'dub-option-image dub-selected' : 'dub-option-image'}>
                      <img src={object.image} alt={object.name} />
                    </div>
                    <div className={selectedIdx === idx ? 'dub-name dub-name-selected' : 'dub-name'}>{object.name}</div>
                  </div>
                );
              })}
            </div>
            <div className={!isLoading ? 'video-dub-input-container' : 'video-dub-input-container disabled'}>
              <div className="d-flex space-between faded">
                <div>Video script</div>
                <div>{charsLeft} characters left</div>
              </div>
              <textarea
                disabled={isLoading}
                maxLength={maxString}
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="video-dub-textarea"
              ></textarea>
            </div>
            <button className="video-dub-button" disabled={isLoading} onClick={sendRequest}>
              {isLoading ? (
                <div className="pd3">
                  <svg className="spinner" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M26.6461 9.43168C27.688 8.9335 28.1404 7.67453 27.5022 6.71197C26.4356 5.10311 25.0647 3.70959 23.46 2.61353C21.2774 1.12285 18.7397 0.235332 16.1037 0.0408469C13.4678 -0.153639 10.8273 0.351812 8.44961 1.50602C6.07188 2.66023 4.04133 4.42221 2.56357 6.61357C1.08581 8.80493 0.213309 11.3478 0.0344026 13.9849C-0.144503 16.6219 0.376542 19.2593 1.54478 21.6302C2.71302 24.0011 4.48697 26.0212 6.68702 27.486C8.30465 28.563 10.1104 29.3116 12.0017 29.6975C13.1333 29.9283 14.131 29.0371 14.2023 27.8844V27.8844C14.2737 26.7318 13.3873 25.7593 12.2699 25.4678C11.1125 25.166 10.0084 24.673 9.00477 24.0048C7.41812 22.9484 6.13877 21.4915 5.29625 19.7817C4.45373 18.0718 4.07795 16.1697 4.20698 14.2679C4.336 12.3662 4.96524 10.5322 6.03099 8.95185C7.09673 7.37147 8.56114 6.10075 10.2759 5.26834C11.9907 4.43594 13.895 4.07142 15.796 4.21168C17.697 4.35194 19.5272 4.99201 21.1012 6.06707C22.0969 6.74711 22.9677 7.58592 23.6814 8.5458C24.3704 9.47259 25.6042 9.92986 26.6461 9.43168V9.43168Z"
                      fill="black"
                    />
                  </svg>
                </div>
              ) : (
                'GENERATE A VIDEO'
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
