export default function Waves({ currentStage }) {
  return (
    <>
      <div className={parseInt(currentStage) > 1 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 2 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 3 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 4 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 5 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 6 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 7 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 8 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 9 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 10 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 11 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 12 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 13 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 14 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 15 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 16 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 17 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 18 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 19 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 20 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 21 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 22 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 23 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 24 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 25 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 26 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 27 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 28 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 29 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 30 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 31 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 32 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 33 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 34 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 35 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 36 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 37 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 38 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 39 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 40 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 41 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 42 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 43 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 44 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 45 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 46 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 47 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 48 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 49 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 50 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 51 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 52 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 53 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 54 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 55 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 56 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 57 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 58 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 59 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 60 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 61 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 62 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 63 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 64 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 65 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 66 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 67 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 68 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 69 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 70 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 71 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
      <div className={parseInt(currentStage) > 72 ? 'dub-wave dub-wave-active' : 'dub-wave'}></div>
    </>
  );
}
