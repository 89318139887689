import React from 'react';
import classes from './Header.module.css';

//import assets
import logo from '../../image/neiro.svg';
import contactsIcon from '../../image/icon-tappable.svg';

const Header = ({ matches }) => {
  return (
    <div className={classes.container}>
      <a href={'/'} style={matches ? { width: '100%', display: 'flex', justifyContent: 'center' } : {}}>
        <img src={logo} alt="logo" className={classes.logo} />
      </a>
      <a className={classes.mobileEmail} href={'mailto:contact@neiro.ai'}>
        <img src={contactsIcon} alt="contactsIcon" />
      </a>
      <a href={'mailto:contact@neiro.ai'} className={classes.email}>
        contact@neiro.ai
      </a>
    </div>
  );
};

export default Header;
